import { PencilIcon } from '@heroicons/react/24/solid'
import { Spinner, Table } from 'common'
import { AppRoutes } from 'config'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
	InvoiceServices,
	TimesheetServices,
	VisitServices,
	VisitTimesheetsServices,
} from 'services'
import { numberFormat } from 'utilities'
import { EdInvoiceCreateForm } from './CreateEdInvoice'
import moment from 'moment'

interface WeeklyHireTableProps {
	job_id?: number
}

export const EDInvoicesTable = ({ job_id }: WeeklyHireTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data, isLoading } = InvoiceServices.useEdInvoicesByJobId(job_id)
	// console.log(data)
	const { data: timesheetsData, isLoading: timesheetsLoading } =
		TimesheetServices.useTimesheets()
	const { data: visitTimesheetsData, isLoading: visitTimesheetsLoading } =
		VisitTimesheetsServices.useVisitTimesheetsByJobId(job_id)

	if (isLoading || visitTimesheetsLoading || timesheetsLoading) {
		return <Spinner />
	}

	const findStartTime = (staffId: number, date: string) => {
		const entry = timesheetsData.find(
			(item: { staff_id: number; date: string }) =>
				item.staff_id === staffId && item.date === date
		)
		return entry ? entry.time_on : null
	}

	const sortAndGroupEntries = (data: any) => {
		const groupedEntries: any = {}

		data.forEach((item: { staff: { id: any }; date: any }) => {
			const key = `${item.staff.id}_${item.date}`
			if (!groupedEntries[key]) {
				groupedEntries[key] = []
			}
			groupedEntries[key].push(item)
		})

		Object.keys(groupedEntries).forEach((key) => {
			groupedEntries[key].sort(
				(
					a: { job_start_time: moment.MomentInput },
					b: { job_start_time: moment.MomentInput }
				) => {
					const aJobStartTime = moment(a.job_start_time, 'HH:mm').valueOf()
					const bJobStartTime = moment(b.job_start_time, 'HH:mm').valueOf()
					return aJobStartTime - bJobStartTime
				}
			)
		})

		return groupedEntries
	}

	const updateStartTimeForMultipleEntries = (groupedEntries: any) => {
		Object.keys(groupedEntries).forEach((key) => {
			const entries = groupedEntries[key]
			for (let i = 0; i < entries.length; i++) {
				if (!entries[i].job_start_time) {
					if (i === 0) {
						const lookedUpStartTime = findStartTime(
							entries[i].staff.id,
							entries[i].date
						)
						entries[i].job_start_time = lookedUpStartTime || entries[i].time_in
					} else {
						entries[i].job_start_time = entries[i - 1].time_off
					}
				}
			}
		})

		return Object.values(groupedEntries).flat()
	}

	const groupedEntries = sortAndGroupEntries(visitTimesheetsData)

	const updatedVisitTimesheetsData: any =
		updateStartTimeForMultipleEntries(groupedEntries)

	let isDayworks = false
	const rowData = data?.map((item: any) => {
		let total = item.total
		let hours = item.hours || item.total_hours || 0
		let visitType = ''
		const jobData = item.jobData
		isDayworks = jobData.is_dayworks
		if (isDayworks && item.type !== 'Inspection') {
			if (!hours) {
				const visitTimesheets = updatedVisitTimesheetsData?.filter(
					(visitTimesheet: any) => {
						const isValidStatus =
							visitTimesheet?.status !== 'Deleted' &&
							visitTimesheet?.status !== 'Inactive'
						const hasTaskId = visitTimesheet.visit
							? visitTimesheet.visit?.task_ids.includes(String(item.task_id))
							: visitTimesheet?.task_ids.includes(String(item.task_id))
						// const matchesInvoiceId = item.date
						// 	? visitTimesheet?.ed_invoice_id === item.id
						// 	: !visitTimesheet?.ed_invoice_id
						const matchesInvoiceId = visitTimesheet?.ed_invoice_id === item.id
						return isValidStatus && hasTaskId && matchesInvoiceId
					}
				)

				hours = visitTimesheets
					? visitTimesheets.reduce((acc: any, timesheet: any) => {
							const startTime = moment(
								timesheet.job_start_time || timesheet.time_in,
								'HH:mm'
							)
							const endTime = moment(timesheet.time_off, 'HH:mm')
							const difference = endTime.diff(startTime, 'hours', true) || 0
							return acc + difference
					  }, 0)
					: 0

				visitType = visitTimesheets[0]?.visit
					? visitTimesheets[0]?.visit?.type
					: ''
				total = hours * item.hourly_rate
			} else if (hours) {
				const visitTimesheets = updatedVisitTimesheetsData?.filter(
					(visitTimesheet: any) => {
						const isValidStatus =
							visitTimesheet?.status !== 'Deleted' &&
							visitTimesheet?.status !== 'Inactive'
						const hasTaskId = visitTimesheet.visit
							? visitTimesheet.visit?.task_ids.includes(String(item.task_id))
							: visitTimesheet?.task_ids.includes(String(item.task_id))
						// const matchesInvoiceId = item.date
						// 	? visitTimesheet?.ed_invoice_id === item.id
						// 	: !visitTimesheet?.ed_invoice_id
						const matchesInvoiceId = visitTimesheet?.ed_invoice_id === item.id
						return isValidStatus && hasTaskId && matchesInvoiceId
					}
				)

				if (hours < 0) {
					hours = visitTimesheets
					? visitTimesheets.reduce((acc: any, timesheet: any) => {
							const startTime = moment(
								timesheet.job_start_time || timesheet.time_in,
								'HH:mm'
							)
							const endTime = moment(timesheet.time_off, 'HH:mm')
							const difference = endTime.diff(startTime, 'hours', true) || 0
							return acc + difference
					  }, 0)
					: 0
				}

				visitType = visitTimesheets[0]?.visit
					? visitTimesheets[0]?.visit?.type
					: ''
				total = hours * item.hourly_rate
			}
		}
		return {
			zone_label: item.zone_label,
			type: item.type,
			description:
				isDayworks && item.type !== 'Inspection'
					? `${visitType} - ${item.description}`
					: item.description || '',
			erect: item.erect,
			dismantle: item.dismantle,
			total: total,
			complete_percent: item.complete_percent,
			PO_Number: item.PO_Number,
			quote: item.quote,
			id: item.id,
			actual_square_meters: item?.taskData?.actual_square_meters,
			status: item.status,
			updatedAt: new Date(item.updatedAt).toLocaleDateString('en-NZ'),
			hourly_rate: item.hourly_rate,
			hours: hours,
			is_dayworks: isDayworks,
			invoice_number: item.xeroReference,
		}
	})

	const columns = [
		{ field: 'zone_label', header: 'Section' },
		{ field: 'type', header: 'Type' },
		{
			field: 'description',
			header: 'Description',
		},
		{
			field: 'updatedAt',
			header: 'Last Time Updated',
		},
		// {
		// 	field: 'actual_square_meters',
		// 	header: 'Installed SQM',
		// },
		{
			field: 'hours',
			header: 'Total Hours',
		},
		{
			field: 'erect',
			header: 'Erect Cost',
			body: (row: { erect: number }) => {
				return numberFormat.format(row.erect || 0)
			},
		},
		{
			field: 'dismantle',
			header: 'Dismantle Cost',
			body: (row: { dismantle: number }) => {
				return numberFormat.format(row.dismantle || 0)
			},
		},
		{
			field: 'hourly_rate',
			header: 'Hourly Rate',
			body: (row: { hourly_rate: number }) => {
				return numberFormat.format(row.hourly_rate || 0)
			},
		},
		{
			field: 'total',
			header: 'Total',
			body: (row: { total: number }) => {
				return numberFormat.format(row.total || 0)
			},
		},
		{
			field: 'complete_percent',
			header: '% Complete',
		},
		{
			field: 'PO_Number',
			header: 'PO Number',
		},
		{
			field: 'quote',
			header: 'Quote Number',
		},
		{
			field: 'invoice_number',
			header: 'Invoice Number',
		},
		{
			field: 'Edit',
			header: 'Edit',
			body: (row: {
				id: number
				status: string
				total: number
				hours: number
				is_dayworks: boolean
			}) => {
				if (row.status === 'Pending') {
					return (
						<Link
							to={{
								pathname: AppRoutes.privateRoutes.editInvoice
									.replace(':id', row.id.toString())
									.replace(':invoiceType', 'edInvoice'),
							}}
							state={{
								background: location,
								name: 'editInvoice',
								total: row.total,
								hours: row.hours,
								isDayworks: row.is_dayworks,
								job_id: job_id,
							}}>
							<PencilIcon className="h-4 w-4 text-gray-500" />
						</Link>
					)
				}
				return <></>
			},
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={rowData}
				isLoading={isLoading ?? false}
				title="E&D Invoices"
				ActionName="Create E&D Invoice"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<EdInvoiceCreateForm
				formType="create"
				heading="Create E&D Invoice"
				open={openCreate}
				setOpen={setOpenCreate}
				job_id={job_id}
				isDayworks={isDayworks}
			/>
		</>
	)
}
