import {
	CreateFile,
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import * as Yup from 'yup'
import moment from 'moment'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { OptionsForDropdownFilter } from 'utilities'
import { StaffServices, SupportServices } from 'services'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const typeOptions = [
	{
		value: 'Urgent Bug Fix',
		label: 'Urgent Bug Fix',
	},
	{
		value: 'Non-Urgent Bug Fix',
		label: 'Non-Urgent Bug Fix',
	},
	{
		value: 'Change Request',
		label: 'Change Request',
	},
	{
		value: 'Variation',
		label: 'Variation',
	},
]

type TypeToDaysKey =
	| 'Urgent Bug Fix'
	| 'Non-Urgent Bug Fix'
	| 'Change Request'
	| 'Variation'

const typeToDays: Record<TypeToDaysKey, number> = {
	'Urgent Bug Fix': 3,
	'Non-Urgent Bug Fix': 7,
	'Change Request': 5,
	Variation: 10,
}

const statusOptions = [
	{
		value: 'Open',
		label: 'Open',
	},
	{
		value: 'In Progress',
		label: 'In Progress',
	},
	{
		value: 'More Info Required',
		label: 'More Info Required',
	},
	{
		value: 'Client Signoff',
		label: 'Client Signoff',
	},
	{
		value: 'Closed',
		label: 'Closed',
	},
]

interface IProps {
	support_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IinitialValues {
	ticketId: string
	type: string
	title: string
	description: string
	notes: string
	file1: string
	file2: string
	link: string
	status: string
	uploadedBy: number
	dueDate: string
	createdAt: string
	dateCompleted: string
	hours: string
}

export const SupportForm = ({
	support_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const userState = useSelector((store: AppStore) => store.user)
	const isScaffm8Staff = userState.email.toLowerCase().includes('scaffm8')

	const { createSupportTicket } = SupportServices.useCreateSupportTicket()
	const { updateSupportTicket } = SupportServices.useUpdateSupportTicket()
	const { data: supportTicketData, isLoading: supportTicketLoading } =
		SupportServices.useSupportTicketById(support_id || undefined)
	const staffDataHook = StaffServices.useStaff()

	const initialValues: IinitialValues = {
		ticketId: supportTicketData?.ticketId || '',
		type: supportTicketData?.type || '',
		title: supportTicketData?.title || '',
		description: supportTicketData?.description || '',
		notes: supportTicketData?.notes || '',
		file1: supportTicketData?.file1 || '',
		file2: supportTicketData?.file2 || '',
		link: supportTicketData?.link || '',
		status: supportTicketData?.status || 'Open',
		uploadedBy: supportTicketData?.uploadedBy || 0,
		dueDate: supportTicketData?.dueDate
			? moment(supportTicketData.dueDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
			: '',
		createdAt: supportTicketData?.createdAt
			? moment(supportTicketData?.createdAt).format('DD/MM/YYYY')
			: moment().format('DD/MM/YYYY'),
		dateCompleted: supportTicketData?.dateCompleted
			? moment(supportTicketData?.dateCompleted).format('DD/MM/YYYY')
			: '',
		hours: supportTicketData?.hours || '',
	}

	const validationSchema = Yup.object({
		type: Yup.string().required('Required'),
		title: Yup.string().required('Required'),
		description: Yup.string().required('Required'),
	})

	const formik = useFormik<IinitialValues>({
		initialValues,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				const JobData = {
					type: values.type,
					title: values.title,
					description: values.description,
					notes: values.notes,
					file1: values.file1,
					file2: values.file2,
					link: values.link,
					status: values.status,
					uploadedBy: values.uploadedBy,
					dueDate: values.dueDate,
				}
				await createSupportTicket(JobData)
			}
			if (formType === 'update' && support_id) {
				const JobData = {
					type: values.type,
					title: values.title,
					description: values.description,
					notes: values.notes,
					file1: values.file1,
					file2: values.file2,
					link: values.link,
					status: values.status,
					uploadedBy: values.uploadedBy,
					dueDate: values.dueDate,
				}
				await updateSupportTicket(support_id, JobData)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		const type = formik.values.type as TypeToDaysKey
		const createdAt = moment(formik.values.createdAt, 'DD/MM/YYYY')

		if (type && typeToDays[type]) {
			const daysToAdd = typeToDays[type]
			const dueDate = createdAt.add(daysToAdd, 'days').format('DD/MM/YYYY')
			formik.setFieldValue('dueDate', dueDate)
		}
	}, [formik.values.type])

	useEffect(() => {
		if (open) {
			if (formik && staffDataHook.data && formType === 'create') {
				const defaultUploadedBy =
					staffDataHook.data?.find((staff) => staff.email === userState.email)
						?.id || 0
				formik.setFieldValue('uploadedBy', defaultUploadedBy)
			}
		} else {
			formik.resetForm()
		}
	}, [open, staffDataHook.data])

	useEffect(() => {
		if (
			formik.values.status === 'Client Signoff' ||
			formik.values.status === 'Closed'
		) {
			formik.setFieldValue('dateCompleted', moment().format('DD/MM/YYYY'))
		} else {
			formik.setFieldValue('dateCompleted', '')
		}
	}, [formik.values.status])

	if (supportTicketLoading) {
		return <Spinner />
	}

	return (
		<div>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div>
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Dropdown
								label="Type"
								id="type"
								options={typeOptions}
								value={formik.values.type}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								error={formik.errors.type}
							/>
						</div>
						<div className="relative group">
							<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
								<InformationCircleIcon
									className="h-6 w-6 text-gray-400"
									aria-hidden="true"
								/>
							</div>

							{/* Tooltip */}
							<div className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 hidden group-hover:block bg-gray-500 text-white text-xs rounded-md py-1 px-2 z-10 whitespace-normal w-[200px]">
								<div className="mb-1">
									<strong>Urgent Bug Fix:</strong> Critical issue that needs
									immediate attention.
								</div>
								<div className="mb-1">
									<strong>Non-Urgent Bug Fix:</strong> Minor issue that does not
									impact major functionality.
								</div>
								<div className="mb-1">
									<strong>Change Request:</strong> For modifying existing
									features or adding new ones.
								</div>
								<div>
									<strong>Variation:</strong> For changes that differ from the
									original plan or contract.
								</div>
							</div>
						</div>
					</div>
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Input
								title="Title"
								id="title"
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.title}
								error={formik.errors.title}
								placeholder="Short Title"
							/>
						</div>
					</div>
					<div className="flex items-center px-3">
						<TextArea
							id="description"
							title="Description"
							value={formik.values.description}
							error={formik.errors.description}
							rows={6}
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
						/>
					</div>
					{isScaffm8Staff && (
						<div className="flex items-center px-3">
							<TextArea
								id="notes"
								title="Notes (For ScaffM8 Staff Only)"
								value={formik.values.notes}
								error={formik.errors.notes}
								rows={6}
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
							/>
						</div>
					)}
					<div className="flex items-center">
						<div className="w-1/2 px-3">
							<h4 className="text-md font-normal leading-5 px-4">File 1</h4>
							<CreateFile
								field="file1"
								setFieldValue={formik.setFieldValue}
								value={formik.values.file1}
							/>
						</div>
						<div className="w-1/2 px-3">
							<h4 className="text-md font-normal leading-5 px-4">File 2</h4>
							<CreateFile
								field="file2"
								setFieldValue={formik.setFieldValue}
								value={formik.values.file2}
							/>
						</div>
					</div>
					<div className="flex items-center px-3">
						<div className="w-full">
							<Input
								title="Link"
								id="link"
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.link}
								placeholder=""
								error={formik.errors.link}
							/>
						</div>
					</div>
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Dropdown
								label="Status"
								id="status"
								options={statusOptions}
								value={formik.values.status}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								error={formik.errors.status}
								disabled={formType === 'create' && !isScaffm8Staff}
							/>
						</div>
						<div className="w-1/2">
							<DateSelect
								id="dueDate"
								title="Due Date"
								value={formik.values.dueDate}
								onChange={formik.setFieldValue}
								error={formik.errors.dueDate}
								disabled={formType === 'create'}
							/>
						</div>
					</div>
					{(formik.values.status === 'Client Signoff' ||
						formik.values.status === 'Closed') && (
						<div className="flex items-start px-3">
							<div className="w-1/2">
								<DateSelect
									id="dateCompleted"
									title="Date Completed"
									value={formik.values.dateCompleted}
									onChange={formik.setFieldValue}
									error={formik.errors.dateCompleted}
								/>
							</div>
							<div className="w-1/2">
								<Input
									title="Hours"
									id="hours"
									type="text"
									handleChange={formik.handleChange}
									handleBlur={formik.handleBlur}
									value={formik.values.hours}
									error={formik.errors.hours}
									placeholder="Hours"
								/>
							</div>
						</div>
					)}
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Dropdown
								id="uploadedBy"
								label="Uploaded By"
								onChange={formik.setFieldValue}
								value={formik.values.uploadedBy}
								error={formik.errors.uploadedBy}
								options={OptionsForDropdownFilter(
									staffDataHook.data,
									'id',
									'full_name',
									'status',
									'Active'
								)}
							/>
						</div>
					</div>
				</div>
			</SideModal>
		</div>
	)
}
