import {
	Badge,
	Button,
	ConfirmationDialog,
	PageHeading,
	Spinner,
	Table,
} from 'common'
import { VisitTimesheetForm } from 'components/VisitTimesheets'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import {
	JobsServices,
	TimesheetServices,
	VisitTimesheetsServices,
} from 'services'
import { Button as PButton } from 'primereact/button'
import DatePicker from 'react-datepicker'
import { isEmpty } from 'lodash'
import { NotificationsContext } from 'context/notifications/toastContext'

interface VisitsTimesheetsTableProps {
	job_id?: number
}
export const VisitsTimesheetsTable = ({
	job_id,
}: VisitsTimesheetsTableProps) => {
	const { showError } = useContext(NotificationsContext)
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	const { data, isLoading } = TimesheetServices.useTimesheets()
	const { data: taskData, isLoading: tasksLoading } =
		JobsServices.useJobTask(job_id)
	const [openVisitTimesheetForm, setOpenVisitTimesheetForm] = useState(false)
	const [timesheetId, setTimesheetId] = useState(undefined)
	const d = new Date()
	const [selectedMonthYear, setSelectedMonthYear] = useState({
		month: d.getMonth() + 1,
		year: d.getFullYear(),
	})

	useEffect(() => {
		if (!openVisitTimesheetForm) {
			setTimesheetId(undefined)
		}
	}, [openVisitTimesheetForm])

	if (timesheetLoading || isLoading || tasksLoading) {
		return <Spinner />
	}

	const filteredTimesheets = [...data]?.filter((timesheet: any) => {
		if (timesheet?.status !== 'Pending - Deleted') {
			return timesheet
		}
	})

	const findStartTime = (staffId: number, date: string) => {
		const entry = filteredTimesheets.find(
			(item: { staff_id: number; date: string }) =>
				item.staff_id === staffId && item.date === date
		)
		return entry ? entry.time_on : null
	}

	const findEndTime = (staffId: number, date: string) => {
		const entry = filteredTimesheets.find(
			(item: { staff_id: number; date: string }) =>
				item.staff_id === staffId && item.date === date
		)
		return entry ? entry.time_off : null
	}

	const sortAndGroupEntries = (data: any) => {
		const groupedEntries: any = {}

		data.forEach((item: { staff: { id: any }; date: any }) => {
			const key = `${item.staff.id}_${item.date}`
			if (!groupedEntries[key]) {
				groupedEntries[key] = []
			}
			groupedEntries[key].push(item)
		})

		Object.keys(groupedEntries).forEach((key) => {
			groupedEntries[key].sort(
				(
					a: { job_start_time: moment.MomentInput },
					b: { job_start_time: moment.MomentInput }
				) => {
					const aJobStartTime = moment(a.job_start_time, 'HH:mm').valueOf()
					const bJobStartTime = moment(b.job_start_time, 'HH:mm').valueOf()
					return aJobStartTime - bJobStartTime
				}
			)
		})

		return groupedEntries
	}

	const updateTimeForMultipleEntries = (groupedEntries: any) => {
		Object.keys(groupedEntries).forEach((key) => {
			const entries = groupedEntries[key]
			for (let i = 0; i < entries.length; i++) {
				if (!entries[i].job_start_time) {
					if (i === 0) {
						const lookedUpStartTime = findStartTime(
							entries[i].staff.id,
							entries[i].date
						)
						entries[i].job_start_time = lookedUpStartTime || entries[i].time_in
					} else {
						entries[i].job_start_time = entries[i - 1].time_off
					}
				}

				if (i === entries.length - 1) {
					if (entries[i].time_off) {
						const lookedUpEndTime = findEndTime(
							entries[i].staff.id,
							entries[i].date
						)
						if (lookedUpEndTime) {
							entries[i].time_off = lookedUpEndTime
						}
					}
				}
			}
		})

		return Object.values(groupedEntries).flat()
	}

	const filteredVisitTimesheets = [...timesheetData]?.filter(
		(visitTimesheet: any) => {
			if (
				visitTimesheet?.status !== 'Deleted' &&
				visitTimesheet?.status !== 'Inactive'
			) {
				return visitTimesheet
			}
		}
	)

	const groupedEntries = sortAndGroupEntries(filteredVisitTimesheets)

	const updatedTimesheetData: any = updateTimeForMultipleEntries(groupedEntries)

	const rowData = updatedTimesheetData
		?.filter(
			(timesheet: { status: string; job_id: number | undefined }) =>
				Number(timesheet.job_id) === job_id &&
				timesheet?.status !== 'Deleted' &&
				timesheet?.status !== 'Inactive'
		)
		?.sort((a: any, b: any) => a.id - b.id)
		?.map((timesheet: any) => {
			const visit: any = timesheet.visit
			const job: any = timesheet?.job
			const staff: any = timesheet?.staff

			const start_time = timesheet.job_start_time
				? moment(timesheet.job_start_time, 'HH:mm')
				: moment(timesheet.time_in, 'HH:mm')
			const end_time = moment(timesheet.time_off, 'HH:mm')
			const difference = end_time.diff(start_time, 'hours', true) || 0
			return {
				record_id: timesheet?.id,
				staff_id: staff?.id,
				id:
					String(job?.id) +
					'_' +
					String(visit?.id) +
					'_' +
					String(staff?.id) +
					'_' +
					String(timesheet?.id),
				visit_id: visit?.id ? `${visit.id}` : timesheet?.date,
				name: staff?.staff_name,
				job_start_time: timesheet.job_start_time || timesheet?.time_in,
				pre_start_time: timesheet?.time_in,
				end_time: timesheet?.time_off,
				date: timesheet?.date,
				job: job?.job_num,
				description: job?.descriptionOfQuote,
				client_name: job?.clientData?.client_name,
				site: job?.site,
				hours: difference,
				confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
				timesheet_status: timesheet?.status,
				task_ids: visit ? visit.task_ids : timesheet.task_ids || [],
				visit_type: visit
					? visit.type
					: timesheet.manual_timesheet_visit_type
					? timesheet.manual_timesheet_visit_type
					: 'Other',
				notes: timesheet?.notes,
				timestamp: timesheet.createdAt
					? moment(timesheet.createdAt).toDate().getTime()
					: -1,
			}
		})

	const getVisitTaskName = (task_ids: string[]) => {
		const taskIds = [...task_ids]

		if (taskIds) {
			const taskNames = []
			for (const taskId of taskIds) {
				const data = taskData?.find((data: any) => data.id === taskId)
				const taskName = data
					? `${data.zone_label} - ${data.type} - ${data.description}`
					: null
				taskNames.push(taskName)
			}
			return taskNames ? taskNames.join(', ') : 'None'
		}

		return 'None'
	}

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
		visit_id: any
	}) => {
		let total = 0

		if (rowData) {
			for (const timesheet of rowData) {
				if (
					Number(timesheet.visit_id) === Number(row.visit_id) &&
					timesheet.timesheet_status !== 'Pending' &&
					Number(timesheet.hours) >= 0
				) {
					const hours = Number(timesheet.hours)
					total += hours
				}
			}
		}
		return total
	}

	const footerTemplate = (data: any) =>
		data.visit_id ? (
			<>
				<td
					colSpan={8}
					style={{ textAlign: 'right' }}
					className="bg-gray-100 font-normal">
					Total Hours
				</td>
				<td colSpan={1} className="bg-gray-100 font-semibold">
					{calculateTimesheetTotal(data)}
				</td>
			</>
		) : (
			<></>
		)

	const columns = [
		{
			field: 'date',
			header: 'Date',
			body: (row: { date: Date }) => {
				return row.date
					? moment(row.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			field: 'visit_id',
			header: 'Visit #',
			body: (row: { visit_id: string; date: string }) => {
				return String(row.visit_id) !== row.date ? (
					<Badge type={'Green'} text={String(row.visit_id)} />
				) : (
					<Badge type={'Orange'} text={'Manual Entry'} />
				)
			},
		},
		{
			field: 'task',
			header: 'Task',
			body: (row: { task_ids: string[] }) => getVisitTaskName(row.task_ids),
		},
		{
			field: 'visit_type',
			header: 'Type',
		},
		{
			field: 'name',
			header: 'Name',
		},
		{ field: 'job_start_time', header: 'Job Start' },
		{ field: 'pre_start_time', header: 'Pre Start' },
		{ field: 'end_time', header: 'Time Off' },
		{ field: 'hours', header: 'Hours' },
		{
			field: 'confined_spaces',
			header: 'Confined Spaces?',
			body: (row: { confined_spaces: boolean }) => {
				return row.confined_spaces ? (
					<Badge type={'Warning'} text={'Confined Space'} />
				) : (
					<Badge type={'Green'} text={'No'} />
				)
			},
		},
		{ field: 'timesheet_status', header: 'Timesheet Status' },
		{ field: 'notes', header: 'Notes' },
	]

	// console.log({ rowData })

	const headerMapping = {
		date: 'Date',
		task: 'Task',
		visit_type: 'Type',
		hours: 'Hours',
	}

	const convertToCSV = (
		data: any[],
		headerMapping: Record<string, string>
	): string => {
		const headers = ['date', 'task', 'visit_type', 'hours']
		const renamedHeaders = headers.map(
			(header) => headerMapping[header] || header
		)

		const rows = data.map((row) => {
			return headers
				.map((header) => {
					const value = header
						.split('.')
						.reduce((acc: any, key: string) => acc[key], row)
					return value ? `"${value}"` : ''
				})
				.join(',')
		})

		return [renamedHeaders.join(','), ...rows].join('\n')
	}

	const downloadCSV = (csv: string, filename: string) => {
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
		const link = document.createElement('a')
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob)
			link.setAttribute('href', url)
			link.setAttribute('download', filename)
			link.style.visibility = 'hidden'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}

	const filterByMonthYear = (
		data: any,
		selectedMonth: any,
		selectedYear: any
	) => {
		return data.filter((item: any) => {
			const itemDate = moment(item.date, 'DD/MM/YYYY')
			console.log({
				itemDate,
				month: itemDate.month(),
				selectedMonth,
				year: itemDate.year(),
				selectedYear,
			})

			return (
				itemDate.month() === selectedMonth - 1 &&
				itemDate.year() === selectedYear
			)
		})
	}

	const groupByDateTasksVisitType = (data: any) => {
		const grouped = data.reduce((acc: any, current: any) => {
			const date = moment(current.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
			const taskIds = current.task_ids.join(',')
			const visitType = current.visit_type

			const key = `${date}-${taskIds}-${visitType}`

			if (!acc[key]) {
				acc[key] = {
					...current,
					hours: current.hours,
					task: getVisitTaskName(current.task_ids),
				}
			} else {
				acc[key].hours += current.hours
			}

			return acc
		}, {})

		return Object.values(grouped)
	}

	const exportCSV = () => {
		if (
			rowData &&
			selectedMonthYear.month !== null &&
			selectedMonthYear.year !== null
		) {
			const filteredData = filterByMonthYear(
				rowData,
				selectedMonthYear.month,
				selectedMonthYear.year
			)
			const groupedData: any[] = groupByDateTasksVisitType(filteredData)

			if (isEmpty(groupedData)) {
				showError('No data to export')
			} else {
				const jobNum = groupedData[0].job
				const clientName = groupedData[0].client_name
				const month = moment()
					.month(selectedMonthYear.month - 1)
					.format('MMMM')
				const year = selectedMonthYear.year
				// const sortedData = groupedData.sort((a: any, b: any) => Number(b.id) - Number(a.id))

				const csv = convertToCSV(groupedData, headerMapping)
				downloadCSV(
					csv,
					`${jobNum} - ${clientName} - ${month} ${year} Visit Timesheets.csv`
				)
			}
		} else {
			showError('No data to export')
		}
	}

	const handleDateChange = (date: any) => {
		const month = date.getMonth() + 1
		const year = date.getFullYear()

		setSelectedMonthYear({ month, year })
	}

	return (
		<>
			<PageHeading
				title="Visit Timesheets"
				createBtn={'Create Visit Timesheet'}
				isEditable={false}
				setOpen={setOpenVisitTimesheetForm}
			/>
			<div className="px-8 flex justify-start space-x-4">
				<ConfirmationDialog
					icon="info"
					title="Export Timesheets"
					body="This action will export the visit timesheets. Select a month/year to be exported:"
					triggerButton={
						<PButton
							type="button"
							icon="pi pi-file"
							label="Export"
							outlined
							onClick={() => {
								null
							}}
						/>
					}
					confirmButton={
						<Button
							size="sm"
							variant="primary"
							onClick={async () => {
								exportCSV()
							}}>
							Export
						</Button>
					}>
					<div className="flex">
						<div className="mt-4 ml-12 mb-40">
							<DatePicker
								selected={
									new Date(
										`${selectedMonthYear.month}/01/${selectedMonthYear.year}`
									)
								}
								onChange={handleDateChange}
								dateFormat="MM/yyyy"
								showMonthYearPicker
								title="Select Month and Year"
								className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-10 rounded-md shadow-sm pl-2"
							/>
						</div>
					</div>
				</ConfirmationDialog>
			</div>
			<Table
				columns={columns}
				data={rowData}
				groupBy="visit_id"
				isLoading={false}
				title=""
				disableButtons
				rowFooterTemplate={footerTemplate}
				sortOrder={-1}
				sortField="timestamp"
			/>
			<VisitTimesheetForm
				timesheet_id={timesheetId}
				heading="Create Visit Timesheet"
				setOpen={setOpenVisitTimesheetForm}
				open={openVisitTimesheetForm}
			/>
		</>
	)
}
